import {
  ProjectModuleType,
  SmInviteType,
  SupportedLocale,
  useGetProjectByIdQuery,
  useSmInviteHiringMutation,
  useSmInviteTrMutation,
} from 'generated/graphql';
import {
  AssigneeType,
  useAssignees,
} from 'hooks/useAssigneesForSelect/useAssignees';
import { FC } from 'react';
import {
  TMultiselectOption,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import InviteNewCandidateForm from './InviteNewCandidateForm';

interface IInviteNewCandidate {
  stageId: number;
  type: ProjectModuleType;
  projectId: number;
  closeModal: () => void;
}

const InviteNewCandidate: FC<IInviteNewCandidate> = ({
  stageId,
  type,
  projectId,
  closeModal,
}) => {
  const { handleMsgType } = useNotification();

  const { parsedAssigneesForSelect } = useAssignees(projectId);

  const [inviteHiringMutation] = useSmInviteHiringMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: 'Invitation sent!',
      });
      closeModal();
    },
  });
  const [inviteTRMutation] = useSmInviteTrMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: 'Invitation sent!',
      });
      closeModal();
    },
  });

  const getProjectByIdResponse = useGetProjectByIdQuery({
    variables: { id: Number(projectId) },
    onError: error =>
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      }),
  });
  const projectLanguages = getProjectByIdResponse?.data?.project?.locales;

  const handleInviteCandidate = (
    givenName: string,
    familyName: string,
    markedAsInternalCandidate: boolean,
    email: string,
    managerEmail: string,
    selectedAssignees: TMultiselectOption[],
    locale: SupportedLocale
  ) => {
    if (type === ProjectModuleType.Hiring) {
      const { projectManagerNewUsers, assignedUserGroups } =
        selectedAssignees?.reduce(
          (
            acc: {
              projectManagerNewUsers: { email: string }[];
              assignedUserGroups: string[];
            },
            option: TMultiselectOption
          ) => {
            if (option.additionalProps.type === AssigneeType.GROUP) {
              acc.assignedUserGroups.push(option.additionalProps.uuid || '');
            } else if (option.additionalProps.type === AssigneeType.USER) {
              acc.projectManagerNewUsers.push({
                email: option.labelNote || '',
              });
            }
            return acc;
          },
          { projectManagerNewUsers: [], assignedUserGroups: [] }
        );

      inviteHiringMutation({
        variables: {
          isCompanyCandidate: markedAsInternalCandidate,
          doneForsAndProjectManagers: [
            {
              doneForNewUser: {
                lastName: familyName,
                firstName: givenName,
                email,
              },
              projectManagerNewUsers: projectManagerNewUsers,
              assignedUserGroups: assignedUserGroups,
            },
          ],
          stageId,
          locale,
          type: type as unknown as SmInviteType,
        },
      });
    } else {
      inviteTRMutation({
        variables: {
          isCompanyCandidate: markedAsInternalCandidate,
          doneForEmails: [email],
          doneByEmails: [managerEmail],
          stageId,
          locale,
          type: type as unknown as SmInviteType,
        },
      });
    }
  };
  return (
    <InviteNewCandidateForm
      availableAssignees={parsedAssigneesForSelect}
      projectLanguages={projectLanguages}
      projectId={projectId}
      type={type}
      onSubmit={handleInviteCandidate}
      onCancel={closeModal}
    />
  );
};

export default InviteNewCandidate;
