import {
  IdentityPermissionRole,
  ProjectCollaborator,
  ProjectCollaboratorOrderField,
  ProjectCollaboratorType,
  SortOption,
  useProjectCollaboratorsFindManyPaginatedQuery,
} from 'generated/graphql';
import { getAccessLevel } from 'helpers/accessLevels';
import { actorRoleLabels } from 'helpers/actorRoles';
import { ReactComponent as IconGroup } from 'icons/group-icon.svg';
import {
  TMultiselectOption,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';

export enum AssigneeType {
  GROUP = 'GROUP',
  USER = 'USER',
}

type ReturnType = {
  parsedAssigneesForSelect: TSelectOption[] | TMultiselectOption[];
};

export const useAssignees = (
  projectId: number,
  parserType: 'permissionRole' | 'accessLevel' = 'permissionRole',
  projectCollaboratorType?: ProjectCollaboratorType
): ReturnType => {
  const { data } = useProjectCollaboratorsFindManyPaginatedQuery({
    variables: {
      args: {
        projectId,
        membersPreviewCount: 6,
        ...(projectCollaboratorType && {
          collaboratorType: projectCollaboratorType,
        }),
      },
      order: {
        direction: SortOption.Asc,
        field: ProjectCollaboratorOrderField.FirstName,
      },
      paginate: {
        size: 1000,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const parserFn =
    parserType === 'accessLevel'
      ? parseAssigneesByAccessLevel
      : parseAssigneesByPermissionRole;

  const parsedData =
    data?.collaborators?.data?.map(collaborator =>
      parserFn(collaborator as ProjectCollaborator)
    ) || [];

  return { parsedAssigneesForSelect: parsedData };
};

export const parseAssigneesByPermissionRole = (
  collaborator: ProjectCollaborator
): TSelectOption | TMultiselectOption => {
  if (collaborator.groupId) {
    return {
      label: `${collaborator.group?.name}`,
      value: `${collaborator.id}`,
      labelNote: collaborator.group?.membersPreview
        .map(member => `${member.firstName} ${member.lastName}`)
        .join(', '),
      labelCounter: {
        icon: <IconGroup />,
        content: `(${collaborator.group?.membersCount || 0})`,
      },
      additionalProps: {
        type: AssigneeType.GROUP,
        uuid: collaborator.group?.subId,
      },
    };
  }

  if (collaborator.userId) {
    return {
      label: `${collaborator.user?.firstName} ${collaborator.user?.lastName}`,
      value: `${collaborator.id}`,
      labelNote: collaborator?.user?.email
        ? `${collaborator.user.email}`
        : 'No email provided',
      labelDescription: `Assigned as: ${
        collaborator.actorRole
          ? actorRoleLabels[collaborator.actorRole]
          : 'Role not specified'
      }`,
      labelCounter: undefined,
      additionalProps: {
        type: AssigneeType.USER,
        uuid: undefined,
      },
    };
  }

  return {};
};

export const parseAssigneesByAccessLevel = (
  collaborator: ProjectCollaborator
): TSelectOption | TMultiselectOption => {
  if (collaborator.groupId) {
    return {
      label: `${collaborator.group?.name}`,
      value: `${collaborator.id}`,
      labelNote: collaborator.group?.membersPreview
        .map(member => `${member.firstName} ${member.lastName}`)
        .join(', '),
      labelCounter: {
        icon: <IconGroup />,
        content: `(${collaborator.group?.membersCount || 0})`,
      },
      additionalProps: {
        type: AssigneeType.GROUP,
        uuid: collaborator.group?.subId,
      },
    };
  }

  if (collaborator.userId) {
    return {
      label: `${collaborator.user?.firstName} ${collaborator.user?.lastName}`,
      value: `${collaborator.id}`,
      labelNote: collaborator?.user?.email
        ? `${collaborator.user.email}`
        : 'No email provided',
      labelCounter: undefined,
      labelDescription: `${getAccessLevel(
        collaborator.user?.companyRoles as IdentityPermissionRole[]
      )} access`,
      additionalProps: {
        type: AssigneeType.USER,
        uuid: undefined,
      },
    };
  }

  return {};
};
