import { DEBOUNCE_TIME_DEFAULT } from 'constants/debounce';
import { useStackOneJobsFindManyPaginatedQuery } from 'generated/graphql';
import { useUrlState } from 'hooks/useUrlState/useUrlState';
import { debounce } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { GridRowSelectionModel, ItemsCount, Table } from '@spotted-zebra-uk/ui';
import { Button, Search } from '@spotted-zebra-uk/ui-components';
import styles from '../AtsLinks.module.scss';
import { EmptyTableContent } from '../EmptyTableContent';
import { COLUMNS, parseRowData } from './LinkedTab.helpers';

const DEFAULT_PAGE_SIZE = 10;

type LinkedTabProps = {
  selectedCompanyId: number;
  selectedCompanyName: string;
  onRefetch: () => void;
};

export function LinkedTab({ selectedCompanyId }: LinkedTabProps) {
  const [searchValue, setSearchValue] = useUrlState('searchQuery');

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  // eslint-disable-next-line
  const [_, setModalOpen] = useState(false);

  const rowCountRef = useRef(0);

  const { data, loading: loadingTable } = useStackOneJobsFindManyPaginatedQuery(
    {
      variables: {
        companyId: selectedCompanyId,
        take: pagination.pageSize,
        skip: pagination.pageSize * pagination.page,
        hasProjectLinked: true,
        search: searchValue,
      },
      fetchPolicy: 'cache-and-network',
      skip: !Boolean(selectedCompanyId),
    }
  );

  const pageInfo = data?.stackOneJobsFindManyPaginated?.pageInfo;

  function handleOpenModal() {
    setModalOpen(true);
  }

  function handleClearSelection() {
    setSelectedRows([]);
  }

  function handleSearchInputChange(searchValue: string) {
    setSearchValue(searchValue);
  }

  const debouncedInputChange = debounce(
    handleSearchInputChange,
    DEBOUNCE_TIME_DEFAULT
  );

  const rows = useMemo(() => parseRowData(data), [data]);

  const rowCount = useMemo(() => {
    if (pageInfo?.itemsTotal !== undefined) {
      rowCountRef.current = pageInfo.itemsTotal;
    }

    return rowCountRef.current;
  }, [pageInfo?.itemsTotal]);

  const selectedRowsCount = selectedRows.length;
  const isUnlinkingBtnDisabled = selectedRowsCount === 0;
  const isSearchInputDisabled = !Boolean(selectedCompanyId);

  return (
    <>
      <Table
        rows={rows}
        columns={COLUMNS}
        autosizeOnMount
        autosizeOptions={{
          expand: true,
          columns: ['project', 'projectId'],
        }}
        loading={loadingTable}
        rowCount={rowCount}
        paginationMode="server"
        paginationModel={pagination}
        onPaginationModelChange={setPagination}
        checkboxSelection
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={selectOptions =>
          setSelectedRows(selectOptions)
        }
        keepNonExistentRowsSelected
        emptyTableContent={<EmptyTableContent />}
        disableColumnFilter
        hasToolbar
        slotProps={{
          toolbar: {
            leftMainAction: <ItemsCount count={rowCount} text="Items" />,
            ...(selectedRowsCount && {
              left: (
                <ItemsCount
                  count={selectedRowsCount}
                  text="Selected"
                  dismissible
                  onClick={handleClearSelection}
                />
              ),
            }),
            rightMainAction: (
              <Button
                onClick={handleOpenModal}
                disabled={isUnlinkingBtnDisabled}
              >
                Unlink projects
              </Button>
            ),
            right: (
              <Search
                onInputChange={debouncedInputChange}
                value={{ label: searchValue || '', value: searchValue || '' }}
                className={styles.toolbarSearch}
                isDisabled={isSearchInputDisabled}
              />
            ),
          },
        }}
        sx={{
          minHeight: '600px',
        }}
      />
    </>
  );
}
