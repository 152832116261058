import { PreviewLink } from 'components/atoms/PreviewLink/PreviewLink';
import { InfoItem } from 'components/molecules/InfoItem/InfoItem';
import { DEBOUNCE_TIME_DEFAULT } from 'constants/debounce';
import { projectsRoutes } from 'constants/navigation';
import { useProjectsForSelect } from 'hooks/useProjectsForSelect/useProjectsForSelect';
import { debounce } from 'lodash';
import { useState } from 'react';
import { Modal, Select, TSelectOption } from '@spotted-zebra-uk/ui-components';
import styles from '../AtsLinks.module.scss';

type LinkingModalTypes = {
  companyId: number;
  isOpen: boolean;
  onConfirm: (projectForLinking: TSelectOption) => void;
  onClose: () => void;
  selectedCompanyName: string;
  selectedJobsCount: number;
  loadingLinking: boolean;
};

export function LinkingModal({
  companyId,
  isOpen,
  onConfirm,
  onClose,
  selectedCompanyName,
  selectedJobsCount,
  loadingLinking,
}: LinkingModalTypes) {
  const {
    selectOptions: selectOptionsProjects,
    loading: loadingProjects,
    searchValue: searchValueProjects,
    setSearchValue: setSearchValueProjects,
    hasMoreItems: hasMoreProjects,
    loadMore: loadMoreProjects,
  } = useProjectsForSelect([companyId]);

  const [selectedProjectForLinking, setSelectedProjectForLinking] =
    useState<TSelectOption>();

  function handleInputChangeProjects(value: string) {
    if (!value && searchValueProjects !== '') {
      setSearchValueProjects('');
      return;
    }

    if (value) {
      setSearchValueProjects(value);
      return;
    }
  }

  const debouncedInputChangeProjects = debounce(
    handleInputChangeProjects,
    DEBOUNCE_TIME_DEFAULT
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Link to project"
      buttonsProps={{
        onConfirmButtonClick: () => {
          selectedProjectForLinking && onConfirm(selectedProjectForLinking);
        },
        onCancelButtonClick: onClose,
        confirmButtonChildren: 'Link',
        cancelButtonChildren: 'Cancel',
        confirmButtonProps: {
          loading: loadingLinking,
          disabled: !selectedProjectForLinking?.value,
        },
      }}
    >
      <section className={styles.modalContent}>
        <InfoItem label="Company" value={selectedCompanyName} />
        <InfoItem label="Requsitions selected" value={selectedJobsCount} />
        <Select
          id="ats-links--project-select"
          value={selectedProjectForLinking}
          loadMoreButtonText="Load more"
          placeholder="Project"
          options={selectOptionsProjects}
          onChange={value => {
            setSelectedProjectForLinking(value);
          }}
          onClearBtnClick={() => setSelectedProjectForLinking(undefined)}
          onInputChange={debouncedInputChangeProjects}
          loadMore={hasMoreProjects}
          onLoadMore={loadMoreProjects}
          isLoading={loadingProjects}
        />
        {selectedProjectForLinking?.value && (
          <PreviewLink
            link={projectsRoutes.editProjectView.url(
              Number(selectedProjectForLinking.value)
            )}
            text="View project"
          />
        )}
      </section>
    </Modal>
  );
}
